import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ThemeProperty } from '../../tenants/tenants.models';
import { TenantResolverService } from '../tenant-resolver.service';
import { ColorService } from './color.service';

@Injectable({ providedIn: 'root' })
export class ThemeService {
	constructor (
		@Inject(DOCUMENT) private readonly document: Document,
		private readonly tenantResolverService: TenantResolverService,
		private readonly colorService: ColorService
	) { }

	initialize (): void {
		this.applyTheme();
	}

	setProperty (property: string, value: string): void {
		this.document.documentElement.style.setProperty(property, value);
	}

	getProperty (property: string, location: HTMLElement = this.document.documentElement): string {
		return getComputedStyle(location).getPropertyValue(property);
	}

	mapColorToProperty (prop: string, value: string): void {
		switch (prop) {
			case ThemeProperty.Primary:
				this.setCssCustomPropToRgbValuesFromColorObj('--color-primary', value);
				break;
			case ThemeProperty.Secondary:
				this.setCssCustomPropToRgbValuesFromColorObj('--color-secondary', value);
				break;
			case ThemeProperty.Accent:
				this.setCssCustomPropToRgbValuesFromColorObj('--color-accent', value);
				break;
			case ThemeProperty.TopBar:
				this.setCssCustomPropToRgbValuesFromColorObj('--color-topbar-base', value);
				this.setCssCustomPropToForegroundColorFromValue('--color-topbar-text', value, '255,255,255', '0,0,0');
				this.setCssCustomPropToForegroundColorFromValue('--color-main-search-bg', value, '255,255,255', '0,0,0', 0.179);
				break;
			case ThemeProperty.SideNav:
				this.setCssCustomPropToRgbValuesFromColorObj('--color-side-navigation-base', value);
				this.setCssCustomPropToForegroundColorFromValue('--color-side-navigation-text', value, '255,255,255', '0,0,0');
				break;
			case ThemeProperty.SideNavScroll:
				this.setCssCustomPropToRgbValuesFromColorObj('--color-side-navigation-scrollbar', value);
				break;
			case ThemeProperty.Base:
				this.setProperty('--color-base', value ? value : null);
				break;
			case ThemeProperty.Loaders:
				this.setCssCustomPropToRgbValuesFromColorObj('--color-loaders', value);
				break;
			case ThemeProperty.CardHeader:
				this.setProperty('--surface-main-header', value ? value : null);
				break;
			case ThemeProperty.CardFooter:
				this.setProperty('--surface-main-footer', value ? value : null);
				break;
		}
	}

	applyTheme (): void {
		const tenant = this.tenantResolverService.getTenant();
		const theme = tenant.theme;
		const loginImage = tenant.loginImage;
		if (theme) {
			Object.keys(theme).forEach(prop => this.mapColorToProperty(prop, theme[prop]));
		}
		if (loginImage) {
			this.setProperty('--login-image', `url(${ loginImage })`);
		}
	}

	private setCssCustomPropToRgbValuesFromColorObj (cssCustomProp: string, value: string): void {
		const { r = null, g = null, b = null } = value ? this.colorService.hexToRGB(value) : {};
		this.setProperty(cssCustomProp, value ? `${ r }, ${ g }, ${ b }` : null);
	}

	private setCssCustomPropToForegroundColorFromValue (cssCustomProp: string, value: string, lightRgb: string, darkRgb: string, threshold?: number): void {
		this.setProperty(cssCustomProp, value ? this.colorService.pickForegroundColor(value, lightRgb, darkRgb, threshold) : null);
	}
}